import sendEvent from '_parts/metrics'
import styles from 'styl/commons/common.styl'

let error404ClassName = styles['error-404'] || 'error-404';
let error451ClassName = styles['error-451'] || 'error-451';
let error503ClassName = styles['error-503'] || 'error-503';

let zvukButtonPlayClassName = styles['zvukButton_play'] || 'zvukButton_play';
let playButtonClassName = styles['play'] || 'play';

document.querySelector('.' + error404ClassName) && sendEvent('404error');
document.querySelector('.' + error451ClassName) && sendEvent('451error');
document.querySelector('.' + error503ClassName) && sendEvent('503error');

window.addEventListener('click', function (e) {

    if (!e.target) return;

    e.target.closest('.' + zvukButtonPlayClassName) && sendEvent('ZVUK_PLAY_ALL');
    e.target.closest('.' + playButtonClassName) && sendEvent('playButtonClick');

});
