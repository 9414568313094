import sendEvent from '_parts/metrics'
import styles from 'styl/commons/common.styl'

let zvukButtonPlayClassName = styles['zvukButton_play'] || 'zvukButton_play';
let zvukButtonDownloadClassName = styles['zvukButton_download'] || 'zvukButton_download';

window.addEventListener('click', function (e) {

    let zvukButtonPlayEl = e.target.closest('.' + zvukButtonPlayClassName);

    if (zvukButtonPlayEl) {

        let decision = zvukButtonPlayEl.getAttribute('decision')

        if (decision) {
            sendEvent('zvooq.' + decision);
        }
    }

    let zvukButtonDownloadEl = e.target.closest('.' + zvukButtonDownloadClassName);

    if (zvukButtonDownloadEl) {

        let decision = zvukButtonDownloadEl.getAttribute('decision')

        sendEvent('downloadButtonClick.' + decision);
    }

})