import sendEvent from "../modules/events";

document.addEventListener("DOMContentLoaded", function(event) { // защита от дебилов

    // отслеживаем клики по элементам

    let hvContainer = document.querySelector('.hvContainer');

    if (hvContainer) {

        let bounds = hvContainer.getBoundingClientRect();
        let windowHeight = window.innerHeight;

        if (windowHeight > bounds.top && bounds.bottom >= 0) {

            sendEvent('showBannerHudeem');

        } else {

            let positionCheck = function() {

                let bounds = hvContainer.getBoundingClientRect();
                let windowHeight = window.innerHeight;

                if (windowHeight > bounds.top && bounds.bottom >= 0) {
                    sendEvent('showBannerHudeem');
                    window.removeEventListener('scroll', positionCheck);
                }

            };

            window.addEventListener('scroll', positionCheck);

        }

    }

});