import $ from 'jquery'
import player from 'js/modules/player/player'
import kinetic from 'js/modules/kinetic'
import reloadLazy from 'js/modules/lazyLoad'
import 'jquery-ui-sortable-npm' // 'jquery-ui-sortable-npm/jquery-ui-sortable.min'
import init from 'js/modules/ajaxPagination'
import listen from 'js/modules/listenAll'
import styles from 'styl/commons/common.styl'

$(document).ready(function () {
    require(['js/modules/search.js']);

    player.init($(`.${styles.songs}`));

    var radioClick = function(){};
    require(['js/modules/radio.js'], function (radio) {
        radioClick = radio;
    });

    init(reloadLazy, true);

    /* Suggests */
    require(['js/modules/suggests.js']);

    require(['js/modules/sharedButtons.js']);
    require(['js/modules/music__slider.js']);
    require(['js/modules/mobileMenu.js']);

    let songsListen = styles.songsListen !== undefined ? `.${styles.songsListen}` : '.songsListen';
    listen($(`${songsListen} .${styles.item}`))

    $.each($(`.${styles.kinetic}`), function () {
        kinetic.init($(this));
    })

    if ($(`.${styles.shared_buttons}`).hasClass('shared_mobile')) {
        $('.ya-share2').css({
            'margin-left': '10px',
            'margin-right': '10px'
        });
        $('.ya-share2__container_size_m .ya-share2__list').css({
            'display': 'flex',
            'justify-content': 'space-between'
        });
        $('.ya-share2__container_size_m .ya-share2__icon').css({
            'width': '30px',
            'height': '30px',
            'background-size': 'unset'
        });
    }
});