import '_parts/zvuk_button';

import '../../../../_newcommon/js/events/hv';
import 'js/events.js'

import $ from 'jquery'
// import player from 'js/modules/player/player'
// import kinetic from 'js/modules/kinetic'
// import reloadLazy from 'js/modules/lazyLoad'
import 'jquery-ui-sortable-npm' // 'jquery-ui-sortable-npm/jquery-ui-sortable.min'
// import init from 'js/modules/ajaxPagination'
// import listen from 'js/modules/listenAll'
import 'slick-carousel'

import styles from 'styl/commons/common.styl'

import Swiper from 'swiper/bundle';
import {Navigation} from 'swiper/modules';

$(document).ready(function () {

    var share = $(`.${styles.shared_buttons}`);

    /* auth */
    require(['js/modules/auth.js']);

    /* favorite */
    require(['js/modules/favorite.js']);

    /* playlist */
    require(['js/modules/playlist.js']);

    /* sortable */
    require(['js/modules/sortable.js']);

    /* sortableFavorite */
    require(['js/modules/sortableFavorite.js']);

    /* search and add in playlist */
    require(['js/modules/search_inPlaylist.js']);

    var $search = $('#main-search');

    $('button[type="submit"]', $search).prop('disabled', false);
    $search.on('submit', function (e) {
        if (!$(`#${styles.search}`).val()){
            e.preventDefault();
        }
    });
    $(`.${styles.reset}`).click(function () {
        $(`input#${styles.search}`).val('');
    });

    if($(`.${styles.button}`).hasClass(`${styles.mobileShare}`)) {
        $(`.${styles.shared_buttons}`).addClass(styles._hide)
    }
    $(`.${styles.mobileShare}`).click(() => share.toggleClass(styles._hide));
    if (share.hasClass('shared_mobile')) {
        $('.ya-share2__container_size_m .ya-share2__list').css({
            'display': 'flex',
            'justify-content': 'space-between'
        });
        $('.ya-share2__container_size_m .ya-share2__icon').css({
            'width': '30px',
            'height': '30px',
            'background-size': 'unset'
        });
    }

    const nav = $('#navigationTabs');
    if (nav.length) {
        const activeItem = $(`#navigationTabs .${styles.activated_tab}`);
        let scrollTo = nav.offset().left + activeItem.offset().left - nav.width() / 2 + activeItem.width() / 3;
        nav.scrollLeft(scrollTo);
    }

    /* Suggests */
    require(['js/modules/suggests.js']);

    //Если аватарки юзера нет - грузим заглушку принудительно.
    if(app.authorized) {
        var pug = $(`.${styles.myMusic} > .${styles.login} > img`),
            myUrl = pug.attr('data-src');
        function checkExists(imageUrl, callback) {
            var img = new Image();
            img.onerror = function() {
                callback(false);
            };
            img.src = imageUrl;
        }
        checkExists(myUrl, function(exists) {
            if(!exists) {
                pug.removeAttr('data-src')
                    .removeAttr('lazyload');
                pug.attr('src','/images/user_default.jpg');
            }
        });
    }


    /*появление формы поиска в header*/
    var searchElement = $(`#${styles.searchElement}`);
    searchElement.on('click', function (event) {
        var currentElement = $(this);
        var activeClass = styles.active,
            displayNoneClass = styles.displayNone;

        if(!currentElement.hasClass(activeClass)){
            currentElement.prev().addClass(displayNoneClass);
            currentElement.next().addClass(displayNoneClass);
            currentElement.addClass(activeClass);
            currentElement.css('overflow', 'visible');
            event.preventDefault();
        }
    });

    //arrow player
    var colPlayer = $(`.${styles.collapsed_player}`);
    $(`.${styles.player}`).on('click', `.${styles.arr_player}`, function () {
        colPlayer.slideToggle(150, function (){
            colPlayer.toggleClass(styles.open_player);
        });
        $(`.${styles.playerDown}`).toggleClass(styles._open);
        $(`.${styles.progress}`).toggleClass(styles.pr_open);
        $(`.${styles['group-mobile-collapsed']}`).toggleClass(styles.visible);
        $(`.${styles['group-mobile']}`).toggle();
        $(`.${styles['track-name']}`).toggle();
        $(`.${styles.header}`).toggle();
        $('body').toggleClass(styles.noScroll);
    });

    //listen
    $(`.${styles.buttonsPlaylist} > .${styles.listen}, .${styles.collectionButtonLine} > .${styles.listen}, .${styles.genreButtonLine} > .${styles.listen} `).on('click',function () {
        $(`.${styles.songs} .${styles.item}`).first().click();
    });

    /*Бургер меню*/
    var headerElement = $(`.${styles.header}`);
    var menuBox = $(`.${styles.box}.${styles.burger_menu}`);
    $(`#${styles['hamburger-icon']}`).on('click', function () {
        var currentElement = $(this);

        if(!currentElement.hasClass(styles.active)){
            currentElement.addClass(styles.active);
            menuBox.addClass(styles.down);
            headerElement.addClass(styles.toFixed);
        }else {
            currentElement.removeClass(styles.active);
            menuBox.removeClass(styles.down);
            headerElement.removeClass(styles.toFixed);
        }
    });

    /*Dropdown Genre*/
    var dropdownMenuGenre = $(`.${styles.sort}`).find('.dropdown-menu_genre');

    $('.dropdownButton_genre').click(function (event) {
        $('.arrow_genre').toggleClass('arrow_genre_active');
        dropdownMenuGenre.slideToggle(250);
        event.preventDefault();
        event.stopPropagation();
    });
    dropdownMenuGenre.click(function () {
        dropdownMenuGenre.slideUp(250);
        $('.arrow_genre').removeClass('arrow_genre_active');
    });
    /*End Dropdown Genre*/

    /* Search Tabs */
    require(['js/modules/search__tabs.js']);

    /* Filter */
    function parseSearchQuery(searchQuery) {
        searchQuery = searchQuery || location.search;
        if (searchQuery.length <= 1) return {};
        return searchQuery.slice(1)
            .split('&')
            .reduce(function (acc, elem) {
                elem = elem.split('=');
                acc[elem[0]] = elem[1];
                return acc
            }, {});
    }

    function stringifySearchQuery(sqobj) {
        return '?' + Object.keys(sqobj).map(function (key) {
            return key + '=' + sqobj[key]
        }).join('&')
    }

    var sort = $(`.${styles.headlineLine} .${styles.sort}`);
    sort.children().click(function () {
        var sort = $(this).data('sort');
        if (!sort) return;
        var parsed = parseSearchQuery();
        parsed.sort = sort;
        if (sort !== 'popular') {
            location.search = stringifySearchQuery(parsed);
        } else {
            delete parsed.sort;
            if(Object.keys(parsed).length !== 0) {
                location.href = location.pathname + stringifySearchQuery(parsed);
            } else {
                location.href=location.pathname;
            }
        }
    });

    //Events
    require(['js/modules/ymTarget.js']);

    if (!app.authorized) {
        $(`.${styles.myMusic}, .${styles.favorite}`).click(function () {
            if (window.ya_global && window.ya_global.reachGoal) {
                window.ya_global.reachGoal('showpopup-auth-click');
            }
        });
    }

    if (document.cookie.indexOf("user_was_authorized=") != -1) {
        if (window.ya_global && window.ya_global.reachGoal) {
            window.ya_global.reachGoal('auth-users-click');
        }
        document.cookie = "user_was_authorized=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }




    document.querySelectorAll('.swiper').forEach((item, index) => {
        item.id = `swiper-${index}`;
        const swiper = new Swiper(`#${item.id}`, {
            modules: [Navigation],
            slidesPerView: "auto",
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    swiper.update()
                }
            });
        };
        const observer = new IntersectionObserver(callback, {
            threshold: 0,
        });
        observer.observe(item);
    })


    const SLICK_SLIDER = styles['slick-slider'],
        SLICK_SLIDER_AUTOPLAY = styles['slickSlider_autoplay'];

    $('.'+SLICK_SLIDER).each(function() {
        let isAuto = $(this).hasClass(SLICK_SLIDER_AUTOPLAY)

        $(this).slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: isAuto,
            autoplaySpeed: 5000,
            dots: isAuto,
            rows: 0,
            accessibility: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: false,
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        arrows: false,
                        swipeToSlide: true,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: false
                    }
                }
            ]
        })
    });

});

if (window.app.tmf_url) {

    let freeClick = !~document.cookie.indexOf("teleTwoDownloadFreeClick");

    if (!~document.cookie.indexOf("teleTwoDownload")) {

        let enable = true;

        let dls = document.querySelectorAll('a.' + (styles['zvukButton_download'] || 'zvukButton_download'));

        [...dls].forEach(function(dlEl){
            dlEl.addEventListener('click', function (e) {

                if (freeClick) {
                    freeClick = false;
                    document.cookie = "teleTwoDownloadFreeClick=; path=/;";
                    return;
                }
                if (!enable) return;

                e.preventDefault();

                enable = false;
                document.cookie = "teleTwoDownload=; path=/;";

                window.open(window.app.tmf_url, '_blank').focus();


            });
        })

    }
}